import React from "react";
import Content from '../views/RepairShop/Main';

export default ({location}) => (
  <Content location={location} seo={{
    lang: 'da-DK',
    title: "Vehicle Care - en stærk løsning for autoværksteder",
    description: 'Vehicle Care - en unik tjeneste til autoværksteder, som letter administrationsarbejdet. Vores teknologi hjælper med at oprette forbindelse til køretøjer og kunder.',
    meta: [{
        name: 'keywords',
        content: 'Autoværksted'
      }]
    }}/>
);