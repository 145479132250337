import React from 'react';
import { Grid } from '@material-ui/core';
import { locale } from '../../../../locale';
import Typography from '../../../../components/Typography';
import SectionWrapper from '../../../../components/SectionWrapper';
import Title from '../../../../components/Title';
import Button from '../../../../components/Button';
import styles from './styles.module.sass';

export default () => locale === 'da' ? (
  <SectionWrapper>
    <Title align='center' color='dark' className={styles.supertitleContainer}>
      Det siger vores værkstedspartnere
    </Title>
    <Grid container justify='center' alignContent='center' alignItems='center'
      className={styles.container}>
      <Grid item container xs={12} sm={12} md={12} lg={5} xl={5}
        alignContent='center'
        alignItems='center'
        justify='flex-start'
        className={styles.callToActionContainer}>
        <Typography variant='h4'
          weight='bold'
          align='left'
          className={styles.titleContainer}>
          Klaus, Autoværkstedet Ø.Jølby
        </Typography>
        <Typography variant='subtitle2'
          weight='light'
          align='left'
          className={styles.subtitleContainer}>
          Det er nemt at komme i gang. Skriv til os for at høre mere om dine muligheder at blive værkstedspartner.
        </Typography>
        <div className={styles.buttonContainer}>
          <a href={'https://www.youtube.com/watch?v=BPE_OchQlp8'}
            target='_blank'
            rel='noopener noreferrer'
            style={{ textDecoration: 'none'}}>
            <Button className={styles.button}>
              Se deres historie
            </Button>
          </a>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={7}
        className={styles.imageWrapper}>
        <iframe title="Testimonials"
          style={{ borderRadius: '16px' }}
          width="100%" height="100%" src="https://www.youtube.com/embed/BPE_OchQlp8"
          frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen/>
      </Grid>
    </Grid>
  </SectionWrapper>
) : null;