import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import { Grid } from '@material-ui/core';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import SectionWrapper from '../../../../components/SectionWrapper';
import Button from '../../../../components/Button';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        pic: imageSharp(fluid: {originalName: {eq: "Contact-Customers.png"}}) {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ pic }) => (
      <SectionWrapper>
        <Grid container justify='center' alignContent='center' alignItems='center'
          className={styles.container}>
          <Grid item container xs={12} sm={12} md={12} lg={5} xl={5}
            alignContent='center'
            alignItems='center'
            justify='flex-start'
            className={styles.callToActionContainer}>
            <Typography variant='h3'
              weight='bold'
              align='left'
              className={styles.titleContainer}>
              {i18n('Contact customers at the right time')}
            </Typography>
            <Typography variant='subtitle2'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('Know that a problem has occurred before a customer calls you and take a more informed decision when advising a customer.')}
            </Typography>
            <div className={styles.buttonContainer}>
              <Link to={`${i18n('_url:repair-shop-features')}`}
                style={{ textDecoration: 'none'}}>
                <Button className={styles.button}>
                  {i18n('Read more')}
                </Button>
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7}
            className={styles.imageWrapper}>
            <Img imgStyle={{ objectFit: 'contain', borderRadius: '16px' }}
              objectFit='contain'
              className={styles.imageContainer}
              fluid={pic.fluid}/>
          </Grid>
        </Grid>
      </SectionWrapper>
    )}/>
);